import React, {useEffect} from 'react'
import Nav from './nav'

//Language imports
import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';


export default function Products() {
  let labels;
  let lang = window.location.pathname.substring(1, 3);
  if (lang == "en") {
    labels = endata.siteGallery;
  } else {
    labels = pldata.siteGallery;
  }
  useEffect(() => {
    document.title = "MetalPegazArt - "+labels.title
  }, [])
  const galleryArray = [
    {id: 1, 
      image: "https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/453042662_122104388756410426_4006601689443786457_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_ohc=DGNV-AhGYdcQ7kNvgFsBlK9&_nc_ht=scontent-lhr6-2.xx&oh=00_AYDiW-h8TDoeOhIqaJ1rwFZe05huOvGitegBcV4ohyo_Fw&oe=66DBA65B", 
      description: "Messerschmitt Bf 109 w wersji G-2", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid02db8knCKru5Lk8rVKr5CqKi55iX8b5xH9Jr8prDJFP175dXNQViiCD9nXwWPPktPVl"},
    {id: 2, 
      image: "https://scontent-lhr8-1.xx.fbcdn.net/v/t39.30808-6/457736718_122111177120410426_6560587547006156739_n.jpg?stp=cp6_dst-jpg_p526x296&_nc_cat=108&ccb=1-7&_nc_sid=127cfc&_nc_ohc=dGvLvnwdtrUQ7kNvgEG5fyd&_nc_ht=scontent-lhr8-1.xx&oh=00_AYDk4BDXRvqK0vjELlRdkeVS4Eu_Jj0xg2zHAUKxpOX-Dw&oe=66DB9C31", 
      description: "Znak z logo", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid02aMXfPtZHDiYnwf9EV9n3WgtxRnSC29fKS8R5reGGqkpi8cyhS4cRbwSnNiPoHLQul"},
    {id: 3, 
      image: "https://scontent-lhr6-1.xx.fbcdn.net/v/t39.30808-6/456510741_122109452756410426_5460444614138823045_n.jpg?stp=cp6_dst-jpg&_nc_cat=102&ccb=1-7&_nc_sid=833d8c&_nc_ohc=tfIAUNaaZCQQ7kNvgHZcksc&_nc_ht=scontent-lhr6-1.xx&oh=00_AYB95Ue75NnMdppRhZdKjaGO9ycuCL2Yc5y8VamgbTeWVA&oe=66DB97AF", 
      description: "Metalowa Roza", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid0drLJjTzvdrca9F3X3Rw5nTNvf2E4N5YAUbHidvVbefHkAehAzaMHxudQmXWuEVFMl"},
    {id: 4, 
      image: "https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/456300853_122109284792410426_1825559312416877265_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=127cfc&_nc_ohc=dKkSmuz_Wi0Q7kNvgF4XuCL&_nc_ht=scontent-lhr6-2.xx&oh=00_AYA0osRtv8OeRXL95Rl5qXD9AmDp1ZxWRMye8ieMlWQJgQ&oe=66DB9EB2", 
      description: "NLR Emblem", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid0qSCDkH8RxzRqzixWynWANUmuAvgjeRXZDRNR5GGH1tpFfx7mYont1e7p2NKqTn4gl"},
  ]
  return (
    <div className={"root"}>
      <div className="header">
        <Nav />
        <h1 className='header-title'>MetalPegazArt</h1>
        <h2>{labels.heading}</h2>
      </div>
      <div className="content">
        <div className="card-container">
        {galleryArray.map((item) => (
          <a href={""+item.link} key={item.id} target="_blank" rel="noreferrer" className={'product-card'}>
            <h2 style={{"display": "none"}} className={'product-label'}>New</h2>
            <img
                src={item.image}
                alt="product"
                className={'product-img'}
            />
            <h2 className={'product-card-container'}>{item.description}</h2>
          </a>
        ))}
        </div>
      </div>
    </div>
  )
}

/*{id: 2, 
      image: "https://i.etsystatic.com/24538453/r/il/7beb6d/5466121109/il_794xN.5466121109_qmir.jpg", 
      description: "opis", 
      link: "https://www.facebook.com/metal.pegazart/"},
    {id: 3, 
      image: "https://i.etsystatic.com/24538453/r/il/15119c/5310882483/il_794xN.5310882483_plf9.jpg", 
      description: "opis", 
      link: "https://www.facebook.com/metal.pegazart/"},
    {id: 4, 
      image: "https://i.etsystatic.com/35049052/r/il/c48fa5/4111160507/il_794xN.4111160507_evey.jpg", 
      description: "opis", 
      link: "https://www.facebook.com/metal.pegazart/"},
    {id: 5, 
      image: "https://i.etsystatic.com/35365225/r/il/293f24/6041911377/il_794xN.6041911377_8uyu.jpg", 
      description: "opis", 
      link: "https://www.facebook.com/metal.pegazart/"},
    {id: 6, 
      image: "https://i.etsystatic.com/19097902/r/il/5777cf/2435343049/il_794xN.2435343049_tc0k.jpg", 
      description: "opis", 
      link: "https://www.facebook.com/metal.pegazart/"},
    {id: 7, 
      image: "https://i.etsystatic.com/28072172/r/il/19b55f/5836414171/il_794xN.5836414171_1uoq.jpg", 
      description: "opis", 
      link: "https://www.facebook.com/metal.pegazart/"},*/