import React from 'react'
import { Link } from 'react-router-dom'
import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';

export default function NotFound() {
  let lang = window.location.pathname.substring(1, 3);
  let labels;
  if (lang == "en") {
    labels = endata.notFound;
  } else {
    labels = pldata.notFound;
  }
  return (
    <div style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} > 
    <h1>404</h1>
    <Link to="/">{labels}</Link>
    </div>
  )
}
