import React, { useState } from 'react'
import Nav from './nav';

export default function Order() {
  const [order, setOrder] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [street, setStreet] = useState("")
  const [town, setTown] = useState("")
  const [postal, setPostal] = useState("")

  const handleSubmit = async (e) => {
    try {
    await fetch('https://webhook.site/4a03de18-b198-4ead-b1ea-ca95087912d1', {
      method: "POST",
      body: JSON.stringify(
        {
          zamowienie: order,
          imie: name,
          email: email,
          telefon: phone,
          ulica: street,
          miasto: town,
          kodpocztowy: postal,
        }
      )
    })}
    catch (error) {
      console.log(error)
    }
  };

  return (
    <div class="product-form-content">
      <Nav />
      <div class="product-form-order">
        <label>Opis zamowienia</label>
        <textarea onChange={e => setOrder(e.target.value)} size="lg" variant="filled" placeholder="Opis Zamuwienia" />
      </div>
      <form class="product-form-details">
          <input variant="filled" onChange={e => setName(e.target.value)} placeholder="Imie"/><label>Imie</label>
          <input variant="filled" onChange={e => setEmail(e.target.value)} placeholder="Email"/><label>Email</label>
          <input variant="filled" onChange={e => setPhone(e.target.value)} placeholder="Telefon"/><label>Telefon</label>
          <input variant="filled" onChange={e => setStreet(e.target.value)} placeholder="Ulica"/><label>Ulica</label>
          <input variant="filled" onChange={e => setTown(e.target.value)} placeholder="Miasto"/><label>Miasto</label>
          <input variant="filled" onChange={e => setPostal(e.target.value)} placeholder="Kod Pocztowy"/><label>Kod Pocztowy</label>
        <div><button onClick={e => handleSubmit(e.target.value)} >Zamow</button></div>
      </form>
    </div>
  )
}
