import { useContext } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
//Context
import {LangProvider, LanguageContext} from './lib/langProvider.js';
//Page Imports
import Home from './pages/home.js';
import Services from './pages/uslugi.js';
import Product from "./pages/product.js";
import Gallery from "./pages/gallery.js";
import Order from "./pages/order.js";
import Templates from "./pages/templates.js";
import Contact from "./pages/contact.js";
import SiteLayout from "./layout.js";
import NotFound from "./pages/NotFound.js";

const useLanguage = () => useContext(LanguageContext);
const LanguageRedirect = () => {
  const { language } = useLanguage();
  return <Navigate to={`/${language}`} />;
};

export default function App() {

  return (
    <LangProvider>
      <Routes>
        <Route path="/" element={<LanguageRedirect />}>
        </Route>
        <Route path="/en" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="order" element={<Order />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="templates" element={<Templates />} />
          <Route path="product/" element={<Product />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/pl" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="uslugi" element={<Services />} />
          <Route path="zamow" element={<Order />} />
          <Route path="galeria" element={<Gallery />} />
          <Route path="szablony" element={<Templates />} />
          <Route path="produkt/" element={<Product />} />
          <Route path="kontakt" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </LangProvider>
  );
}

function Layout() {
  return (
    <SiteLayout/>
  );
}

/*
<Routes>
        <Route path="/" element={<LanguageRedirect />}>
        <Route index element={<Home />} />
          <Route path="uslugi" element={<Services />} />
          <Route path="zamow" element={<Order />} />
          <Route path="galeria" element={<Gallery />} />
          <Route path="szablony" element={<Templates />} />
          <Route path="produkt/" element={<Product />} />
          <Route path="kontakt" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      <Routes>


<Route index element={<Home />} />
<Route path="/uslugi" element={<Services />} />
<Route path="/zamow" element={<Order />} />
<Route path="/galeria" element={<Gallery />} />
<Route path="/szablony" element={<Templates />} />
<Route path="/produkt/" element={<Product />} />
<Route path="/kontakt" element={<Contact />} />
<Route path="*" element={<NotFound />} />*/