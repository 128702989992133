import React, {useEffect} from 'react'
import useSEO from '../lib/meta.js';
import Nav from './nav.js'
import { FaFacebookF, FaTiktok, FaTwitter } from "react-icons/fa";
//Language imports
import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';

export default function Home() {
  useSEO()
  let labels;
  let lang = window.location.pathname.substring(1, 3);
  if (lang == "en") {
    labels = endata.siteHome;
  } else {
    labels = pldata.siteHome;
  }
  useEffect(()=> {
    document.title = "MetalPegazArt - "+labels.title
  }, [])
  return (
    <main className={"root"}>
            <div className={"header"}>
                <Nav />
                <h1 className={'header-title'}>Metal Pegaz-Art</h1>
                <h3>{labels.heading}</h3>
                <div className={'card-container'}>
                    <a target="_blank" href="https://www.facebook.com/metal.pegazart/" className={'social-icon'}>
                    <FaFacebookF />
                    </a>
                    <a target="_blank" href="/site" className={'social-icon'}>
                    <FaTiktok />
                    </a>
                    <a target="_blank" href="/site" className={'social-icon'}>
                    <FaTwitter />
                    </a>
                </div>
            </div>
            <div className={"content"}>
                <h2>{labels.subheading}</h2>
                <div className={'card-container'}>
                    <a href="/site" className={"card"}>
                        <img
                            src="https://wixplosives.github.io/codux-assets-storage/add-panel/image-placeholder.jpg"
                            alt=""
                            className={'card-img'}
                        />
                        <h2>Product Name</h2>
                        <h3>{labels.keyword}</h3>
                    </a>
                    <a href="/site" className={"card"}>
                        <img
                            src="https://wixplosives.github.io/codux-assets-storage/add-panel/image-placeholder.jpg"
                            alt=""
                            className={'card-img'}
                        />
                        <h2>Product Name</h2>
                        <h3>Buy</h3>
                    </a>
                    <a href="/site" className={"card"}>
                        <img
                            src="https://wixplosives.github.io/codux-assets-storage/add-panel/image-placeholder.jpg"
                            alt=""
                            className={'card-img'}
                        />
                        <h2>Product Name</h2>
                        <h3>Buy</h3>
                    </a>
                    <a href="/site" className={"card"}>
                        <img
                            src="https://wixplosives.github.io/codux-assets-storage/add-panel/image-placeholder.jpg"
                            alt=""
                            className={'card-img'}
                        />
                        <h2>Product Name</h2>
                        <h3>Buy</h3>
                    </a>
                </div>
            </div>
            <div className={"footer"}></div>
        </main>
  )
}


/*<div class='page-content'>
      <div class="top-home-content">
        <Nav />
        <h1 class="home-branding-container"> Metal Pegaz-Art</h1>
        <p class="home-slogan-container">
          Co tylko klient Wymarzy
        </p>
        <div class="socials-home-container">
          <Card size="lg"><CardBody>
            <a href="http://somesite" rel="noreferrer" target='_blank'><FaFacebookF /></a>
          </CardBody></Card>
          <Card size="lg"><CardBody>
            <a href="http://somesite" rel="noreferrer" target='_blank'><FaTiktok /></a>
          </CardBody></Card>
          <Card size="lg"><CardBody >
            <a href="http://somesite" rel="noreferrer" target='_blank'><FaTwitter /></a>
          </CardBody></Card>
        </div>
      </div>
      <div class="bottom-home-content">
        <p class="bottom-home-slogan">Top Produkty</p>
        <Card borderRadius={'25px'} variant='unstyled' size='lg'  >
          <CardBody class="home-product-container">
            <Card direction={{ base: 'column', sm: 'row' }} class="home-product" >
              <CardBody>
                <img alt="example" 
                  src='https://images.unsplash.com/photo-1501166222995-ff31c7e93cef?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWV0YWx8ZW58MHx8MHx8fDA%3D' 
                  width="fit-content" height="fit-content"/>
                <p style={{maxHeight: 100, "overflow": "auto"}} >Przykladowy produkt</p>
                <Button>Zamow</Button>
              </CardBody>
            </Card>
            <Card direction={{ base: 'column', sm: 'row' }} class="home-product">
              <CardBody>
                <img alt="example" 
                  src='https://images.unsplash.com/photo-1501166222995-ff31c7e93cef?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWV0YWx8ZW58MHx8MHx8fDA%3D' 
                  width="fit-content" height="fit-content"/>
                <p style={{maxHeight: 100, "overflow": "auto"}}>Przykladowy produkt </p>
                <Button>Zamow</Button>
              </CardBody>
            </Card>
            <Card direction={{ base: 'column', sm: 'row' }} class="home-product">
              <CardBody>
                <img alt="example" 
                  src='https://images.unsplash.com/photo-1501166222995-ff31c7e93cef?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWV0YWx8ZW58MHx8MHx8fDA%3D' 
                  width="fit-content" height="fit-content"/>
                <p style={{maxHeight: 100, "overflow": "auto"}}>Przykladowy produkt</p>
                <Button >Zamow</Button>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
    </div> */