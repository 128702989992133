import React,{ createContext, useState, useContext } from 'react'

const LanguageContext = createContext();

function LangProvider({children}) {
    const getUserLanguage = () => {
        const language = navigator.language || navigator.userLanguage;
        return language.startsWith('en') ? 'en' : 'pl';
      };
      
    const [language, setLanguage] = useState(getUserLanguage());
    return (
      <LanguageContext.Provider value={{ language, setLanguage }}>
        {children}
      </LanguageContext.Provider>
    );
}

export {LangProvider, LanguageContext}