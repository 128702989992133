import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Nav from './nav'

import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';

export default function Templates() {
  let lang = window.location.pathname.substring(1, 3);
  let locations; let points;
  if (lang == "en") {
    locations= endata.siteTemplates;
    points = endata.links
  } else {
    locations= pldata.siteTemplates;
    points = pldata.links
  }
  const [templateArray, setTemplateArray] = useState([])
  useEffect(() => {
    document.title = "MetalPegazArt - " + locations.title;
    const fetchData = async () => {
      const res = await fetch("https://www.metalpegazart.pl/api/product/")
      setTemplateArray(await res.json())
    }
    fetchData()
    }, [])

  return (
    <div className={"root"}>
      <div className="header">
        <Nav />
        <h1 className='header-title'>MetalPegazArt</h1>
        <h2>{locations.heading}</h2>
      </div>
      <div className="content">
        <div className="card-container">
        {templateArray.map((item) => (
          <Link to={"/"+lang+"/"+ points.product +"?p="+item.id} key={item.id} target="_blank" className={'product-card'}>
          <h2 style={{"display": "none"}} className={'product-label'}>{locations.keyword}</h2>
          <img
              src={item.image}
              alt="product"
              className={'product-img'}
          />
          <h2 className={'product-card-container'}>{item.name}</h2>
        </Link>
        ))}
        </div>
      </div>
      <div className="footer"></div>
    </div>
  )
}
