import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
//Page imports
import Nav from './nav.js'
import NotFound from './NotFound.js';
//Language imports
import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';

export default function Product() {
  //Url lookup logic
  //Product ID is a Search Paramater looking for 'p' in current URL location
  const id = new URLSearchParams(useLocation().search).get('p')
  let labels;
  let lang = window.location.pathname.substring(1, 3);
  if (lang == "en") {
    labels = endata.siteProduct;
  } else {
    labels = pldata.siteProduct;
  }
  //Product array declaration as empty object
  const [productArray, setProductArray] = useState([])
  useEffect(() => { //On page load
    //Set the page title
    document.title = "MetalPegazArt - "+labels.title
    //get data from api
    const fetchData = async () => {
        const result = await fetch(`https://www.metalpegazart.pl/api/product/?id=${id}`);
        const data = await result.json();
        setProductArray(data); //assign the data thats been recieved
    }
    fetchData()
  }, [])
  //Form controls and data
  const [formCompelete, setFormComplete] = useState(false) //Shows "modal" when done
  const [formFields, setForm] = useState({
    Name: "", Email: "",Phone: "",Street: "",Town: "", Postal: ""
  })
  const [errors, setErrors] = useState({
    Name: false, Email: false, Phone: false, Street: false, Town: false, Postal: false
  }); //Throws errors when fields dont match

  const checkField = (value, field) => {
    if (field.id == "Email") {
      setErrors((prevErrors) => ({
        ...prevErrors, //copies errors array then assigns true if passed
        [field.id]: !/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
      }));
    } else if (field.id == "Phone") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field.id]: !/^\d+$/.test(value)
      }));
    } else {
      setErrors((prevErrors) => ({
          ...prevErrors,
          [field.id]: value.length < 3
      }));
    }//once check is done
    setForm((prevForm) => ({
      ...prevForm,//copies the form array
      [field.id]: value //assigns the value to the field
    }));
  };

  //Image selection
  //First selector element gets value from API's first item
  const [element1, setElement1] = useState(productArray[0]?.base)
  const handleSetElement1 = (e) => { //On change of value updates the element
    setElement1(e.target.value);
  }
  //Selected image is the array object used to display the product image
  const selectedImage = productArray.find( //based on selection in element 1 finds
    (item) => item.base === element1 //the appropriate array item
  ) || productArray[0]; //Otherwise defaults to first item
  

  const handleSubmit = async (e) => {
    try {
      setFormComplete(true)
      await fetch('https://webhook.site/7a54b73c-73b1-4588-a215-b88ae9cbe551', {
        method: "POST",
        body: JSON.stringify(
          {
            version_id: selectedImage?.version_id,
            base: selectedImage?.base,
            color: document.querySelector('#colorSelect').value,
            price: selectedImage?.value,
            name: formFields.Name,
            email: formFields.Email,
            phone: formFields.Phone,
            street: formFields.Street,
            town: formFields.Town,
            postal: formFields.Postal,
            language: lang
          }
        )
    });
    } catch (error) {
        console.log('Error:', error);
    }
  }
  //Page render
  //if array is empty, throw a not found
  if (productArray.length === 0) {
    return <NotFound />;
  } else {
  return (
    <div className={"root"}>
      <div className="header">
        <Nav />
        <h1 className='header-title'>MetalPegazArt</h1>
      </div>
      <div className="content">
        <div className={(!formCompelete) ? 'form-popup-disabled' : 'form-popup'}>
          <h1>{labels.confirmTitle}</h1>
          <p>{labels.confirmMessage}</p>
        </div>
        <div className={ (!formCompelete) ? 'form-container': 'form-container form-disabler'}>
        <div className="form-left">
          <img alt="example" 
                src={selectedImage?.src}
                className='form-image'
                />
            <p style={{fontSize: 32}}>{labels.Elements}</p>
            <p>{labels.Element1}: </p>
            <select id="baseSelect" className="form-select" variant="filled" onChange={handleSetElement1}>
            {productArray.map((item, index) => (
              <option key={index} value={item.base}>{item.base}</option>
            ))}
            </select>
            <p>{labels.Element2}: </p>
            <select id="colorSelect" className="form-select" variant="filled">
            {productArray.map((item, index) => (
              <option key={index} value={item.color}>{item.color}</option>
            ))}
          </select>
        </div>
        <div className="form-right">
          <label className='form-label'>{labels.formName}</label><input id='Name' onChange={e => checkField(e.target.value, document.querySelector('#Name'))} placeholder={labels.formName} className={errors.Name ? `contact-input contact-input-error` : `contact-input`}/>
          <label className='form-label'>{labels.formEmail}</label><input id="Email" onChange={e => checkField(e.target.value, document.querySelector('#Email'))} placeholder={labels.formEmail} className={errors.Email ? `contact-input contact-input-error` : `contact-input`}/>
          <label className='form-label'>{labels.formPhone}</label><input id="Phone" onChange={e => checkField(e.target.value, document.querySelector('#Phone'))} placeholder={labels.formPhone} className={errors.Phone ? `contact-input contact-input-error` : `contact-input`}/>
          <label className='form-label'>{labels.formStreet}</label><input id="Street" onChange={e => checkField(e.target.value, document.querySelector('#Street'))} placeholder={labels.formStreet} className={errors.Street ? `contact-input contact-input-error` : `contact-input`}/>
          <label className='form-label'>{labels.formTown}</label><input id="Town" onChange={e => checkField(e.target.value, document.querySelector('#Town'))} placeholder={labels.formTown} className={errors.Town ? `contact-input contact-input-error` : `contact-input`}/>
          <label className='form-label'>{labels.formPostal}</label><input id="Postal" onChange={e => checkField(e.target.value, document.querySelector('#Postal'))} placeholder={labels.formPostal} className={errors.Postal ? `contact-input contact-input-error` : `contact-input`}/>
          <label className='form-label'>{labels.keyword}:</label>
          <h1>{selectedImage?.value}</h1>
           <button className='contact-submit' onClick={e => handleSubmit(e.target.value)}>{labels.button}</button>
        </div>
      </div>
      </div>
      <div className='footer'></div>
    </div>
  )
}
}