import { useEffect } from 'react';

const useSEO = () => {
  useEffect(() => {
    let pageTitle = "MetalPegazArt - Strona Główna"
    let link = "www.metalpegazart.pl"
    let logoSrc = link + "/fiery-logo.png"
    let slogan = 'Wedle Życzenia Klienta';

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", slogan);
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = "description";
      newMetaDescription.content = slogan;
      document.head.appendChild(newMetaDescription);
    }

    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
      metaViewport.setAttribute("content", "width=device-width, initial-scale=1");
    } else {
      const newMetaViewport = document.createElement('meta');
      newMetaViewport.name = "viewport";
      newMetaViewport.content = "width=device-width, initial-scale=1";
      document.head.appendChild(newMetaViewport);
    }

    const metaCharset = document.querySelector('meta[charset]');
    if (metaCharset) {
      metaCharset.setAttribute("charset", "UTF-8");
    } else {
      const newMetaCharset = document.createElement('meta');
      newMetaCharset.setAttribute("charset", "UTF-8");
      document.head.appendChild(newMetaCharset);
    }

    const metaRobots = document.querySelector('meta[name="robots"]');
    if (metaRobots) {
      metaRobots.setAttribute("content", "index, follow");
    } else {
      const newMetaRobots = document.createElement('meta');
      newMetaRobots.name = "robots";
      newMetaRobots.content = "index, follow";
      document.head.appendChild(newMetaRobots);
    }

    const linkCanonical = document.querySelector('link[rel="canonical"]');
    if (linkCanonical) {
      linkCanonical.setAttribute("href", link);
    } else {
      const newLinkCanonical = document.createElement('link');
      newLinkCanonical.rel = "canonical";
      newLinkCanonical.href = link;
      document.head.appendChild(newLinkCanonical);
    }

    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute("content", pageTitle);
    } else {
      const newOgTitle = document.createElement('meta');
      newOgTitle.setAttribute("property", "og:title");
      newOgTitle.content = pageTitle;
      document.head.appendChild(newOgTitle);
    }

    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogDescription) {
      ogDescription.setAttribute("content", slogan);
    } else {
      const newOgDescription = document.createElement('meta');
      newOgDescription.setAttribute("property", "og:description");
      newOgDescription.content = slogan;
      document.head.appendChild(newOgDescription);
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute("content", logoSrc);
    } else {
      const newOgImage = document.createElement('meta');
      newOgImage.setAttribute("property", "og:image");
      newOgImage.content = logoSrc;
      document.head.appendChild(newOgImage);
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
      ogUrl.setAttribute("content", link);
    } else {
      const newOgUrl = document.createElement('meta');
      newOgUrl.setAttribute("property", "og:url");
      newOgUrl.content = link;
      document.head.appendChild(newOgUrl);
    }

    const twitterCard = document.querySelector('meta[name="twitter:card"]');
    if (twitterCard) {
      twitterCard.setAttribute("content", logoSrc);
    } else {
      const newTwitterCard = document.createElement('meta');
      newTwitterCard.name = "twitter:card";
      newTwitterCard.content = logoSrc;
      document.head.appendChild(newTwitterCard);
    }

    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute("content", pageTitle);
    } else {
      const newTwitterTitle = document.createElement('meta');
      newTwitterTitle.name = "twitter:title";
      newTwitterTitle.content = pageTitle;
      document.head.appendChild(newTwitterTitle);
    }

    const twitterDescription = document.querySelector('meta[name="twitter:description"]');
    if (twitterDescription) {
      twitterDescription.setAttribute("content", slogan);
    } else {
      const newTwitterDescription = document.createElement('meta');
      newTwitterDescription.name = "twitter:description";
      newTwitterDescription.content = slogan;
      document.head.appendChild(newTwitterDescription);
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) {
      twitterImage.setAttribute("content", logoSrc);
    } else {
      const newTwitterImage = document.createElement('meta');
      newTwitterImage.name = "twitter:image";
      newTwitterImage.content = logoSrc;
      document.head.appendChild(newTwitterImage);
    }

  }, []);
};

export default useSEO;