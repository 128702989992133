import React from 'react'
import Nav from './nav.js'
export default function Services() {
  React.useEffect(() => {
    document.title = "MetalPegazArt - Uslugi"
  }, [])
  return (
    <div className={"root"}>
      <div className="header">
        <Nav />
        <h1 className='header-title'>MetalPegazArt</h1>
      </div>
      <div className={"content"}>
      <article>
  <h1>Zakres usług Metal Pegaz-Art.</h1>
  <b>Tworzenie wszelakich ozdób z:</b>
  <ul>
    <li>Stali</li>
    <li>Stali nierdzewnej</li>
    <li>Aluminium</li>
    <li>Mosiądzu</li>
  </ul>
  <b>Tworzenie oświetlenia LED do wytworzonych ozdób z metalu.</b>
  <ul>
    <li>LED biały</li>
    <li>LED RGB (kolorowy)</li>
    <li>LED RGB z sektorami (pojedyncze detale ozdoby mają własny kolor)</li>
  </ul>
  <b>Tworzenie ozdób z klasy Rękodzieła (nie ma 2 identycznych ozdób)</b>
  <b>Tworzenie ozdób na zamówienie według pomysłu, konceptu i osobistego życzenia klienta nawet jeśli nie ma go w spisie produktów</b>
  <h2>Tworzenie projektu od Zera</h2>
  Najwyższa precyzja zamówionej ozdoby
  Wiele opcji rozwojowych do momentu wysłania projektu na laser
  Dowolna możliwość wykończenia ozdoby:
  <ul>
    <li>Lakiery</li>
    <li>Zardzewienie</li>
    <li>Okleiny</li>
    <li>Ręczne malowanie precyzyjne</li>
  </ul>
  Cena uzależniona od stopnia trudności zamówienia, typu materiałów, skomplikowania wzoru dla Lasera oraz wykończenia ozdoby.
  <h2>Usługi spawalnicze z metod:</h2>
  <ul>
    <li>Metoda MAG 135 - proces spawania łukowego elektrodą topliwą w osłonie gazów aktywnych (Stal czarna)</li>
    <li>Metoda TIG 141 - proces spawania z wykorzystaniem nietopliwej elektrody wolframowej w osłonie gazów obojętnych (Stal nierdzewna, aluminium, mosiądz)</li>
  </ul>
</article>

      </div>
    </div>
  )
}
