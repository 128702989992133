import React from 'react'
import { Outlet } from "react-router-dom";

export default function SiteLayout() {
  let lang = navigator.language
  document.documentElement.lang = lang;
  return (
    <div className="app-content">
      <Outlet />
    </div>
  )
}
