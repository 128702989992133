import React, {useState} from 'react'
import { Link } from "react-router-dom";
import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';

export default function Nav() {

  let lang = window.location.pathname.substring(1, 3);
  let labels; let locations;
  if (lang == "en") {
    labels = endata.navigation;
    locations= endata.links;
  } else {
    labels = pldata.navigation;
    locations= pldata.links;
  }
  let [menu, setMenu] = useState(false)

  return (
        <nav className={'nav-container'}>
          <div className={'nav-body'}>
            <Link className={'nav-button'} to={'/'+ lang +'/'+ locations.services}>{labels.services}</Link>
            <Link className={'nav-button nav-compact'} to={'/'+lang +'/'+locations.gallery}>{labels.gallery}</Link>
            <Link to={"/"+lang} >
            <img alt={"company logo"} className={'nav-logo'} src='/fiery-logo.png'/>
            </Link>
            <Link to={'/'+ lang +"/"+ locations.templates} className={'nav-button'}>{labels.templates}</Link>
            <Link to={'/'+ lang +"/"+ locations.contact} className={'nav-button'}>{labels.contact}</Link>
            <button className='nav-hidden' onClick={() => setMenu(menu => !menu)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" fill="black"/>
                <rect y="11" width="24" height="2" fill="black"/>
                <rect y="18" width="24" height="2" fill="black"/>
              </svg>
            </button>
            </div>
            {menu ? <div className='nav-sub'>
              <Link className={'nav-button nav-compact'} to={'/'+ lang +'/'+ locations.services}>{labels.services}</Link>
              <Link to={'/'+ lang +"/"+ locations.templates} className={'nav-button nav-compact'}>{labels.templates}</Link>
            <Link to={'/'+ lang +"/"+ locations.contact} className={'nav-button nav-compact'}>{labels.contact}</Link>
              </div> : null}
        </nav>
  )
}